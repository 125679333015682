<template>
    <div
        class="pt-12 md:pt-22 pb-12 purple-gradient-bg diagonally-rounded"
        :class="extraPaddingBottom ? 'md:pb-28' : 'md:pb-16'"
    >
        <div class="container px-0">
            <SectionWrapper :title="$t('testimonials.title')" is-carousel-wrapper centered-title>
                <div id="testimonials-carousel-container" class="swiper-container relative">
                    <div id="testimonials-carousel" class="swiper lg:w-8/12 mx-auto">
                        <div class="swiper-wrapper">
                            <div
                                v-for="(testimonial, i) in testimonials"
                                :key="i"
                                class="swiper-slide"
                                style="height: auto"
                            >
                                <TestimonialCard
                                    :name="testimonial.name"
                                    :avatar="testimonial.image"
                                    :position="testimonial.position"
                                    :message="testimonial.content"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="swiper-nav-container lg-pronounced-navigation-button">
                        <div class="swiper-button-prev"></div>
                        <div class="swiper-pagination"></div>
                        <div class="swiper-button-next"></div>
                    </div>
                </div>
            </SectionWrapper>
        </div>
    </div>
</template>

<script>
import SectionWrapper from '@/components/UI/SectionWrapper';
import TestimonialCard from '@/components/Cards/TestimonialCard/TestimonialCard';

export default {
    name: 'SectionTestimonials',
    components: {
        SectionWrapper,
        TestimonialCard,
    },
    props: {
        testimonials: {
            type: Array,
            required: true,
        },
        extraPaddingBottom: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    mounted() {
        this.testimonialsCarousel = new this.$swiper('#testimonials-carousel', {
            slidesPerView: 1,
            spaceBetween: 20,
            resistance: true,
            resistanceRatio: 0,
            allowTouchMove: this.$device.isMobileOrTablet || window.innerWidth < 768,
            speed: 500,
            pagination: {
                el: '#testimonials-carousel-container .swiper-pagination',
                type: 'bullets',
                clickable: true,
            },
            navigation: {
                nextEl: '#testimonials-carousel-container .swiper-button-next',
                prevEl: '#testimonials-carousel-container .swiper-button-prev',
            },
            loop: true,
            autoplay: {
                delay: 6000,
            },
        });
    },
    beforeDestroy() {
        this.testimonialsCarousel.destroy();
    },
};
</script>

<style scoped>
.swiper {
    @apply pb-5 md:pb-10;
}
#testimonials-carousel-container {
    .swiper-button-prev,
    .swiper-button-next {
        @apply lg:top-12 lg:-translate-y-0;
    }
}
</style>
