<template>
    <SectionWrapper :title="title" centered-title :theme-light="themeLight">
        <div id="partners-carousel-container" class="swiper-container relative pb-4 md:pb-12 -mt-12">
            <div id="partners-carousel" class="swiper">
                <div class="swiper-wrapper items-center">
                    <div v-for="partner in partners" :key="partner.id" class="swiper-slide">
                        <img :src="partner.image" :alt="`${partner.title} logo`" style="transform: scale(0.8)" />
                    </div>
                </div>
            </div>
            <div class="swiper-nav-container">
                <div class="swiper-pagination"></div>
            </div>
        </div>
    </SectionWrapper>
</template>

<script>
import Swiper from 'swiper/swiper-bundle.min';
import 'swiper/swiper-bundle.min.css';
import SectionWrapper from '@/components/UI/SectionWrapper';

export default {
    name: 'SectionPartners',
    components: {
        SectionWrapper,
    },
    props: {
        partners: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        themeLight: {
            type: Boolean,
            required: false,
        },
    },
    mounted() {
        this.partnersCarousel = new Swiper('#partners-carousel', {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 20,
            resistance: true,
            resistanceRatio: 0,
            allowTouchMove: this.$device.isMobileOrTablet || window.innerWidth < 768,
            speed: 1000,
            breakpoints: {
                520: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                },
                1024: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                },
                1280: {
                    slidesPerView: 5,
                    slidesPerGroup: 5,
                },
                1921: {
                    slidesPerView: 6,
                    slidesPerGroup: 6,
                },
            },
            pagination: {
                el: '#partners-carousel-container .swiper-pagination',
                type: 'bullets',
                clickable: true,
            },
            loop: true,
            autoplay: {
                delay: 5000,
            },
        });
    },
    beforeDestroy() {
        this.partnersCarousel.destroy();
    },
};
</script>

<style scoped></style>
