import { render, staticRenderFns } from "./SectionWrapper.vue?vue&type=template&id=2c6af628&scoped=true&"
import script from "./SectionWrapper.vue?vue&type=script&lang=js&"
export * from "./SectionWrapper.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c6af628",
  null
  
)

export default component.exports