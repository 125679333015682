<template>
    <div class="swiper-container relative">
        <div ref="swiper" class="swiper md:pb-16">
            <div ref="swiperWrapper" class="swiper-wrapper">
                <div v-for="(article, i) in articles" :key="i" class="swiper-slide">
                    <ArticleCard
                        v-if="advice"
                        :url="localePath(`/job-seeker/advice/${article.slug}`)"
                        :title="article.title"
                        :thumbnail="article.cover_image"
                        :minutes-to-read="article.minutes_to_read"
                    />
                    <ArticleCard
                        v-else
                        :title="article.title"
                        :description="article.lead"
                        :minutes-to-read="article.minutes_to_read"
                        :url="localePath(`/news/${article.slug}`)"
                        :thumbnail="article.cover_image_cropped"
                    />
                </div>
            </div>
        </div>

        <div class="swiper-nav-container">
            <div ref="prevBtn" class="swiper-button-prev"></div>
            <div ref="pagination" class="swiper-pagination"></div>
            <div ref="nextBtn" class="swiper-button-next"></div>
        </div>
    </div>
</template>

<script>
import ArticleCard from '@/components/Cards/ArticleCard/ArticleCard';

export default {
    name: 'ArticleCarousel',
    components: {
        ArticleCard,
    },
    props: {
        articles: {
            type: Array,
            required: true,
        },
        advice: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    mounted() {
        this.articlesCarousel = new this.$swiper(this.$refs.swiper, {
            slidesPerView: 1.2,
            spaceBetween: 20,
            resistance: true,
            resistanceRatio: 0,
            allowTouchMove: this.$device.isMobileOrTablet || window.innerWidth < 768,
            speed: 500,
            breakpoints: {
                500: {
                    slidesPerView: 1.6,
                },
                640: {
                    slidesPerView: 2,
                },
                1024: {
                    slidesPerView: 3,
                },
            },
            pagination: {
                el: this.$refs.pagination,
                type: 'bullets',
                clickable: true,
            },
            navigation: {
                nextEl: this.$refs.nextBtn,
                prevEl: this.$refs.prevBtn,
            },
        });
    },
    beforeDestroy() {
        if (this.articlesCarousel) {
            this.articlesCarousel.destroy();
        }
    },
};
</script>

<style scoped>
.swiper {
    @media (min-width: 640px) {
        @apply px-8 -mx-8;
    }
}
</style>

<style>
.swiper-slide .job-card__details {
    /* margin-bottom of the cards, used when cards are displayed in a grid */
    margin-bottom: 0;
}
</style>
