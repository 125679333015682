var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative sm:mb-20 -mx-5 px-5 md:mx-0 md:px-0",class:[_vm.isServiceCard ? 'py-12' : '', _vm.content ? 'mb-10' : 'mb-16'],attrs:{"id":_vm.slug ? _vm.slug : ''}},[(_vm.isServiceCard)?_c('div',{staticClass:"absolute top-0 w-full sm:w-11/12 h-full bg-[#03000D] z-0",class:[
            _vm.reversed ? 'rounded-bl-100 md:rounded-bl-200 right-0' : 'rounded-br-100 md:rounded-br-200 left-0',
            _vm.details ? 'light-card-shadow' : '' ]}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"relative grid grid-cols-12 gap-5 z-10"},[_c('div',{staticClass:"relative col-span-12 md:col-span-6 rounded-br-100 mb-8 sm:mb-12 md:mb-0",class:_vm.reversed ? 'md:col-start-1 md:order-1' : 'md:col-start-7 md:order-2'},[_c('div',{staticClass:"relative",class:{
                    'lg:pl-10': _vm.withGapBetweenTextAndImage && !_vm.reversed,
                    'lg:pr-10': _vm.withGapBetweenTextAndImage && _vm.reversed,
                }},[_c('div',{staticClass:"w-full aspect-h-3 aspect-w-4 bg-cover bg-center rounded-sm",class:{
                        'rounded-tl-100 lg:rounded-tl-150 xl:rounded-tl-200': _vm.roundedTL,
                        'rounded-tr-100 lg:rounded-tr-150 xl:rounded-tr-200': _vm.roundedTR,
                        'rounded-br-100 lg:rounded-br-150 xl:rounded-br-200': _vm.roundedBR,
                        'rounded-bl-100 lg:rounded-bl-150 xl:rounded-bl-200': _vm.roundedBL,
                    },style:(("background-image: url(" + _vm.illustration + ")"))}),_vm._v(" "),(_vm.decorator)?_c('div',{staticClass:"absolute -bottom-6 bg-contain bg-center bg-no-repeat w-28 h-28",class:_vm.reversed ? 'left-12' : 'right-12',style:(("background-image: url(" + _vm.decorator + ")"))}):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"col-span-12 sm:col-span-11 flex flex-col justify-center items-start",class:[
                _vm.reversed
                    ? 'sm:col-start-2 md:col-span-6 md:col-start-7 md:order-2 md:pl-10'
                    : _vm.withGapBetweenTextAndImage
                    ? 'md:col-span-6 md:pr-12 col-start-1 md:order-1'
                    : 'md:col-span-5 col-start-1 md:order-1',
                _vm.isServiceCard ? (_vm.reversed ? 'md:px-10' : 'md:pl-10') : '' ]},[_c('h3',{staticClass:"title text-[26px] md:text-2xl xl:text-3xl mb-6"},[(_vm.index)?_c('span',{staticClass:"text-magenta-400"},[_vm._v(_vm._s(_vm.index)+" - ")]):_vm._e(),_vm._v("\n                "+_vm._s(_vm.title)+"\n            ")]),_vm._v(" "),(_vm.content)?_c('div',{staticClass:"tinymce-content",domProps:{"innerHTML":_vm._s(_vm.content)}}):(_vm.description)?_c('p',[_vm._v(_vm._s(_vm.description))]):_vm._e(),_vm._v(" "),_vm._t("default"),_vm._v(" "),(_vm.withContactButton)?_c('NuxtLink',{staticClass:"p-component p-button p-button-primary mt-8 sm:mt-12",attrs:{"to":_vm.localePath('/contact')}},[_c('span',{staticClass:"p-button-label"},[_vm._v(" "+_vm._s(_vm.$t('generic.contact_us'))+" ")])]):_vm._e()],2)]),_vm._v(" "),(_vm.details.length)?_c('div',{staticClass:"relative grid grid-cols-12 gap-5 pt-14 sm:pt-18 sm:pb-8 md:pb-14 z-10"},[_c('div',{staticClass:"col-span-12",class:_vm.reversed ? 'sm:col-span-11 sm:col-start-2' : 'sm:col-span-11'},[_c('div',{staticClass:"md:px-10"},[_c('div',{staticClass:"aux-text mb-5"},[_vm._v(_vm._s(_vm.title)+" "+_vm._s(_vm.$t('solutions.services')))]),_vm._v(" "),_c('ul',{staticClass:"grid grid-cols-2 gap-4 lg:gap-5"},_vm._l((_vm.details),function(detail){return _c('li',{key:(_vm.slug + "-" + (detail.id)),staticClass:"relative title text-lg lg:text-xl col-span-2 md:col-span-1 pl-8"},[_c('i',{staticClass:"absolute top-2 left-0 pi pi-check text-yellow-400 mr-2"}),_vm._v("\n                        "+_vm._s(detail.title)+"\n                    ")])}),0)])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }