<template>
    <article class="article-card h-full transition-all">
        <NuxtLink :to="url" class="relative h-full flex flex-col">
            <div class="relative w-auto h-auto aspect-w-4 aspect-h-3 z-0 overflow-hidden">
                <VLazyImage
                    :src="thumbnail"
                    class="object-cover w-full h-full"
                    :alt="title"
                    :intersection-options="{ rootMargin: '0px 0px 700px 0px' }"
                />
            </div>

            <div
                class="
                    relative
                    flex flex-col flex-grow
                    justify-between
                    pt-4
                    px-5
                    pb-8
                    bg-white
                    z-10
                    -mt-20
                    text-black
                    rounded-tl-sm rounded-tr-40 rounded-br-sm rounded-bl-40
                    mr-4
                "
            >
                <div class="flex flex-col h-full justify-between">
                    <h3 class="title title-base mb-4">{{ title }}</h3>

                    <span v-if="minutesToRead" class="flex items-center">
                        <i class="pi pi-clock mr-2"></i>
                        {{ minutesToRead }} {{ $tc('generic.minute_to_read', minutesToRead) }}
                    </span>

                    <span v-else class="p-component p-button p-button-secondary p-button-text">
                        <i class="pi pi-arrow-right p-button-icon-right"></i>
                        <span class="p-button-label">{{ $t('generic.read_more') }}</span>
                    </span>
                </div>
            </div>
        </NuxtLink>
    </article>
</template>

<script>
import VLazyImage from 'v-lazy-image/v2';

export default {
    name: 'ArticleCard',
    components: {
        VLazyImage,
    },
    props: {
        url: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        thumbnail: {
            type: String,
            required: true,
        },
        minutesToRead: {
            type: Number,
            required: false,
            default: null,
        },
    },
};
</script>

<style>
body:not(.touchscreen) .article-card img {
    transition: opacity 0.2s, transform 0.4s;
}

body:not(.touchscreen) .article-card:hover img {
    transform: scale(1.05);
}
body:not(.touchscreen):not(.safari) .bg-white .article-card:hover {
    filter: drop-shadow(0 2px 20px rgba(0, 0, 0, 0.1));
}
</style>
