<template>
    <div
        :id="slug ? slug : ''"
        class="relative sm:mb-20 -mx-5 px-5 md:mx-0 md:px-0"
        :class="[isServiceCard ? 'py-12' : '', content ? 'mb-10' : 'mb-16']"
    >
        <div
            v-if="isServiceCard"
            class="absolute top-0 w-full sm:w-11/12 h-full bg-[#03000D] z-0"
            :class="[
                reversed ? 'rounded-bl-100 md:rounded-bl-200 right-0' : 'rounded-br-100 md:rounded-br-200 left-0',
                details ? 'light-card-shadow' : '',
            ]"
        ></div>

        <div class="relative grid grid-cols-12 gap-5 z-10">
            <div
                class="relative col-span-12 md:col-span-6 rounded-br-100 mb-8 sm:mb-12 md:mb-0"
                :class="reversed ? 'md:col-start-1 md:order-1' : 'md:col-start-7 md:order-2'"
            >
                <div
                    class="relative"
                    :class="{
                        'lg:pl-10': withGapBetweenTextAndImage && !reversed,
                        'lg:pr-10': withGapBetweenTextAndImage && reversed,
                    }"
                >
                    <div
                        class="w-full aspect-h-3 aspect-w-4 bg-cover bg-center rounded-sm"
                        :class="{
                            'rounded-tl-100 lg:rounded-tl-150 xl:rounded-tl-200': roundedTL,
                            'rounded-tr-100 lg:rounded-tr-150 xl:rounded-tr-200': roundedTR,
                            'rounded-br-100 lg:rounded-br-150 xl:rounded-br-200': roundedBR,
                            'rounded-bl-100 lg:rounded-bl-150 xl:rounded-bl-200': roundedBL,
                        }"
                        :style="`background-image: url(${illustration})`"
                    ></div>
                    <div
                        v-if="decorator"
                        class="absolute -bottom-6 bg-contain bg-center bg-no-repeat w-28 h-28"
                        :class="reversed ? 'left-12' : 'right-12'"
                        :style="`background-image: url(${decorator})`"
                    ></div>
                </div>
            </div>

            <div
                class="col-span-12 sm:col-span-11 flex flex-col justify-center items-start"
                :class="[
                    reversed
                        ? 'sm:col-start-2 md:col-span-6 md:col-start-7 md:order-2 md:pl-10'
                        : withGapBetweenTextAndImage
                        ? 'md:col-span-6 md:pr-12 col-start-1 md:order-1'
                        : 'md:col-span-5 col-start-1 md:order-1',
                    isServiceCard ? (reversed ? 'md:px-10' : 'md:pl-10') : '',
                ]"
            >
                <h3 class="title text-[26px] md:text-2xl xl:text-3xl mb-6">
                    <span v-if="index" class="text-magenta-400">{{ index }} - </span>
                    {{ title }}
                </h3>

                <div v-if="content" class="tinymce-content" v-html="content"></div>

                <p v-else-if="description">{{ description }}</p>

                <slot></slot>

                <NuxtLink
                    v-if="withContactButton"
                    :to="localePath('/contact')"
                    class="p-component p-button p-button-primary mt-8 sm:mt-12"
                >
                    <span class="p-button-label"> {{ $t('generic.contact_us') }} </span>
                </NuxtLink>
            </div>
        </div>

        <div v-if="details.length" class="relative grid grid-cols-12 gap-5 pt-14 sm:pt-18 sm:pb-8 md:pb-14 z-10">
            <div class="col-span-12" :class="reversed ? 'sm:col-span-11 sm:col-start-2' : 'sm:col-span-11'">
                <div class="md:px-10">
                    <div class="aux-text mb-5">{{ title }} {{ $t('solutions.services') }}</div>
                    <ul class="grid grid-cols-2 gap-4 lg:gap-5">
                        <li
                            v-for="detail in details"
                            :key="`${slug}-${detail.id}`"
                            class="relative title text-lg lg:text-xl col-span-2 md:col-span-1 pl-8"
                        >
                            <i class="absolute top-2 left-0 pi pi-check text-yellow-400 mr-2"></i>
                            {{ detail.title }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FeatureCard',
    props: {
        index: {
            type: Number,
            required: false,
            default: null,
        },
        slug: {
            type: String,
            required: false,
            default: null,
        },
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: false,
            default: null,
        },
        content: {
            type: String,
            required: false,
            default: null,
        },
        illustration: {
            type: String,
            required: true,
        },
        decorator: {
            type: String,
            required: false,
            default: null,
        },
        reversed: {
            type: Boolean,
            required: false,
            default: false,
        },
        roundedTL: {
            type: Boolean,
            required: false,
            default: false,
        },
        roundedTR: {
            type: Boolean,
            required: false,
            default: false,
        },
        roundedBL: {
            type: Boolean,
            required: false,
            default: false,
        },
        roundedBR: {
            type: Boolean,
            required: false,
            default: false,
        },
        details: {
            type: Array,
            required: false,
            default: () => [],
        },
        withContactButton: {
            type: Boolean,
            required: false,
            default: false,
        },
        withGapBetweenTextAndImage: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        isServiceCard() {
            return this.getRouteBaseName() === 'services';
        },
    },
};
</script>

<style scoped></style>
