<template>
    <div class="px-5 rounded flex flex-col lg:flex-row h-full">
        <div
            v-if="avatar"
            class="w-24 h-24 lg:w-36 lg:h-36 lg:min-w-36 lg:min-h-36 rounded-full mr-12 mb-4 lg:mb-0 bg-cover bg-center"
            :style="`background-image: url(${avatar})`"
        ></div>

        <div class="flex flex-col justify-center">
            <p class="mb-5 md:mb-10">{{ message }}</p>
            <span class="block font-bold">{{ name }}</span>
            <span class="block">{{ position }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TestimonialCard',
    props: {
        name: {
            type: String,
            required: true,
        },
        avatar: {
            type: String,
            required: false,
            default: null,
        },
        position: {
            type: String,
            required: true,
        },
        message: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped></style>
